* {
  padding: 0;
  margin: 0;
}

.leaflet-tooltip-left:before {
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  color: black !important;
  font-weight: bold !important;
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.7) !important;
}
.leaflet-tooltip-right:before {
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  color: black !important;
  font-weight: bold !important;
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.7) !important;
}
[role='tooltip'] {
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  color: black !important;
  font-weight: bold !important;
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.7) !important;
}
[role='tooltip']::after {
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  color: black !important;
  font-weight: bold !important;
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.7) !important;
}
[role='tooltip']::before {
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  color: black !important;
  font-weight: bold !important;
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.7) !important;
}
.leaflet-tooltip {
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  color: black !important;
  font-weight: bold !important;
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.7) !important;
}
.leaflet-tooltip-own {
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  color: black !important;
  font-weight: bold !important;
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.7) !important;
}
[class^='leaflet-popup-'] {
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  color: black !important;
  font-weight: bold !important;
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.7) !important;
}
